@tailwind base;
@tailwind components;
@tailwind utilities;

.root,
#root,
#docs-root {
    --primary: #00a0fd;
    --secondary: #003968;
    --accent: #172e35;
    --background: #FFF;
    --background-2: #f3f3f5;
    --main: #1D2731;
    --header: #1D2731;
}